<template>
  <div class="margin-y">
    <div class="heading-small margin-y" v-text="$translations.form.date" />
    <DatePickerL
      v-if="!reset"
      v-model="date"
      :disabled-dates="disabledDates"
      :min-date="new Date()"
      :loading="loading"
      @month-change="onMonthChanged"
      @year-change="onYearChanged"
      @date-select="getTimes"
      :without-last-days="true"
      :without-first-days="true"
    />
    <AppointmentTimeSelector
      v-if="options.time && options.time.length > 0 && !loading"
      :time="time"
      :options="options.time"
      @timeChanged="onChanged"
    />
  </div>
</template>

<script>
import {
  getDate,
  SpecialistsSchedulesServices,
  DatePickerL
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

import AppointmentTimeSelector from '@/components/fields/AppointmentTimeSelector'

export default {
  name: 'AppointmentDateSelector',
  components: { AppointmentTimeSelector, DatePickerL },
  props: {
    specialistId: String,
    serviceId: String,
    modality: String,
    reschedule: Boolean
  },
  data () {
    const dateTime = this.$luxon.DateTime
    return {
      date: new Date(),
      reset: false,
      time: null,
      disabledDates: [],
      availableDateTimesRaw: [],
      loading: false,
      options: {
        time: []
      },
      dateTime
    }
  },
  methods: {
    onChanged (value) {
      this.time = value
      this.$emit('changed', value)
    },
    onMonthChanged (event) {
      this.getSchedule({
        year: event.year,
        month: event.month - 1
      })
    },
    onYearChanged (event) {
      this.getSchedule({
        year: event.year,
        month: event.month - 1
      })
    },
    onDayChanged () {
      this.$emit('dayChanged', this.date)
    },
    getSchedule (
      props = {
        year: new Date().getFullYear(),
        month: new Date().getMonth()
      }
    ) {
      if (!(this.specialistId && this.serviceId)) {
        return
      }

      this.loading = true
      this.time = null
      this.options.time = null

      SpecialistsSchedulesServices.get({
        specialistId: this.specialistId,
        serviceId: this.serviceId,
        params: {
          year: props.year,
          month: props.month + 1,
          timeZone: this.timeZone,
          modality: this.modality
        }
      })
        .then((response) => {
          this.availableDateTimesRaw = response.availableDateTimes || []
          const availableDateTimes = this.monthTimezoneReduction(
            response.availableDateTimes || []
          )
          this.disabledDates = this.getDisabledDates(
            availableDateTimes,
            getDate(new Date(), props)
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    monthTimezoneReduction (availableDateTimes) {
      const set = availableDateTimes
        .map((d) => {
          return this.dateTime.fromMillis(d).setZone(this.timeZone)
        })
        .reduce((days, availableDateTime) => {
          days.add(availableDateTime.startOf('day').toFormat('yyyy-MM-dd'))
          return days
        }, new Set())

      return Array.from(set)
    },
    getDisabledDates (availableDays, date = getDate()) {
      const daysInMonths = this.dateTime.fromJSDate(date).daysInMonth
      const dates = []
      const disabledDates = []

      for (let i = 0; i < daysInMonths; i++) {
        const d = this.dateTime
          .fromJSDate(date)
          .set({ day: i + 1 })
          .toFormat('yyyy-MM-dd')
        dates.push(d)
      }

      dates.forEach((o) => {
        if (!availableDays.includes(o)) {
          disabledDates.push(
            this.dateTime
              .fromFormat(o, 'yyyy-MM-dd')
              .setZone(this.timeZone)
              .toJSDate()
          )
        }
      })

      return disabledDates
    },
    getTimes () {
      this.options.time = null
      this.time = null
      const time = []
      const date = this.dateTime.fromJSDate(this.date).toMillis()

      this.availableDateTimesRaw.forEach((timestamp) => {
        const timestampDate = this.dateTime
          .fromMillis(timestamp)
          .setZone(this.timeZone)
          .toFormat('yyyy-MM-dd')
        const today = this.dateTime.fromMillis(date).toFormat('yyyy-MM-dd')

        const validation = timestampDate === today

        if (validation) {
          time.push({
            code: this.dateTime
              .fromMillis(timestamp)
              .setZone(this.timeZone)
              .toJSDate(),
            raw: timestamp,
            name: this.dateTime
              .fromMillis(timestamp)
              .setZone(this.timeZone)
              .toFormat('hh:mm a')
          })
        }

        if (time.length === 0) {
          return
        }

        this.options.time = time
      })
    },
    async resetDatePicker () {
      this.reset = true
      await this.getSchedule()
      this.reset = false
    }
  },
  computed: {
    ...mapGetters({
      timeZone: 'userTimezone'
    })
  },
  watch: {
    modality: {
      handler () {
        this.date = null
        this.resetDatePicker()
      },
      immediate: true
    },
    serviceId: {
      handler () {
        this.date = null
        this.resetDatePicker()
      },
      immediate: true
    },
    date: {
      handler () {
        this.onDayChanged()
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass">
.hour-selector
  margin-left: -16px
  margin-right: -16px
  padding-bottom: 16px
</style>
