<template>
  <div class="flex gap-0.5">
    <div class="self-center body-small">
      {{ timeZone }}
    </div>
    <div class="self-center icon-settings-off heading text-purple"></div>
  </div>
</template>

<script>
export default {
  name: 'TimeZoneSelector',
  computed: {
    timeZone () {
      return this.$store.getters.userTimezone
    }
  }
}
</script>
