<template>
  <div>
    <div class="flex gap-1 margin-y">
      <div class="flex-grow self-center heading-small">
        {{ $translations.form.hour }}
      </div>

      <div class="flex-none self-center">
        <TimeZoneSelector />
      </div>
    </div>

    <Dropdown
      :placeholder="$translations.form.hour"
      :options="mapOptions"
      :chipsType="$store.getters.platform !== 'web'"
      :value="(time && time.name) || null"
      @value="selected($event)"
    />
  </div>
</template>

<script>
import TimeZoneSelector from '@/components/fields/TimeZoneSelector'
import { Dropdown } from '@seliaco/red-panda'

export default {
  name: 'AppointmentTimeSelector',
  components: {
    TimeZoneSelector,
    Dropdown
  },
  props: {
    options: Array,
    time: {
      name: String,
      code: String,
      raw: String
    }
  },
  data () {
    return {
      value: this.time
    }
  },
  watch: {
    $value () {
      this.value = this.time
    }
  },
  computed: {
    mapOptions () {
      return this.options.map((item) => ({
        name: item.name,
        value: item
      }))
    }
  },
  methods: {
    selected (value) {
      this.value = value
      this.$emit('timeChanged', value)
    }
  }
}
</script>

<style lang="sass">
.hour-selector
  margin-left: -16px
  margin-right: -16px
  padding-bottom: 16px
</style>
