<template>
  <div
    v-selectable-container
    class="service-card"
    :class="{ active: active }"
    @click="handleSelectService"
  >
    <!-- left side component -->
    <div class="left-side" :style="setBackground" />

    <!-- service info -->
    <div class="service-info-container" :style="{ width: 'calc(100% - 36px)' }">
      <!-- name -->
      <div class="service-name" v-text="service.service.name" />

      <!-- description -->
      <div class="service-description" v-text="service.service.description" />

      <!-- price and duration -->
      <div class="service-price-and-duration">
        <!-- duration -->
        <div class="chip" :class="{ active: active }">
          <div class="icon icon-timer-off" :class="{ active: active }" />
          <div class="text">{{ service.service.duration }} min.</div>
        </div>

        <!-- price -->
        <template v-if="false">
          <div class="chip" :class="{ active: active }">
            <!-- default -->
            <template>
              <div class="icon icon-money-off" :class="{ active: active }" />
              <div class="text" v-text="price" />
            </template>
          </div>
        </template>
      </div>
    </div>

    <!-- selection icon -->
    <div
      @click="handleSelectService"
      class="selection-icon radio-icon"
      :class="{ active: active }"
      v-if="!withoutAction"
    />
  </div>
</template>

<script>
import {
  selectableContainer,
  CurrencyFilter as currency,
  ServiceIcons
} from '@seliaco/red-panda'

export default {
  name: 'ServiceCard',
  directives: {
    selectableContainer
  },
  props: {
    service: Object,
    active: Boolean,
    withoutAction: Boolean
  },
  data () {
    return {
      styleConfig: ServiceIcons
    }
  },
  methods: {
    handleSelectService () {
      this.$emit('changed', this.service)
    }
  },
  computed: {
    setBackground () {
      const icon = this.service.service.icon_name
      const background = this.styleConfig[icon].background

      return `background: ${background}`
    },
    price () {
      return currency(
        this.service.pricing.total / 100,
        this.service.currency.toUpperCase()
      )
    }
  },
  filters: {
    currency
  }
}
</script>

<style lang="sass" scoped>
.service-card-wrapper
  display: flex
  flex-direction: column
  gap: 8px

.service-card
  display: flex
  position: relative
  overflow: hidden
  cursor: pointer
  padding: 16px 16px 16px 32px
  border-radius: 16px
  gap: 16px
  transition-property: color, background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color
  transition-timing-function: cubic-bezier(.4,0,.2,1)
  transition-duration: .3s
  border: 2px solid var(--gray-10)

  &.active
    color: var(--purple)
    border: 2px solid var(--purple-50)
    background: var(--purple-5)
    box-shadow: 0px 5px 20px rgba(131, 80, 230, 0.2)

  &.disable
    background: var(--gray-5)
    pointer-events: none

.left-side
  width: 16px
  position: absolute
  height: 100%
  left: 0
  top: 0

.service-info-container
  flex-grow: 1

  .service-name
    font-size: var(--tiny)
    font-weight: 600

  .service-description
    font-size: var(--sm)
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    color: var(--gray-70)

  .service-price-and-duration
    display: flex
    gap: 8px
    margin-top: 8px
    .chip
      display: flex
      gap: 4px
      padding: 8px 12px
      border-radius: 8px
      color: var(--dark-gray)
      font-weight: 600
      font-size: 14px
      white-space: nowrap
      background: var(--gray-10)
      &.active
        background: var(--purple-10)
    .text
      align-self: center
      font-size: var(--tiny)
      color: var(--gray-80)

.radio-icon
  border: 2px solid #737373
  border-radius: 9999px
  height: 20px
  width: 20px
  flex: none
  cursor: pointer
  transition: 0.3s
  display: flex
  justify-content: center
  justify-items: center

  &.active
    border-color: #C1A7F2

.radio-icon.active::after
  margin-top: 3px
  content: ""
  height: 10px
  width: 10px
  border-radius: 9999px
  background-color: #8350E6

.icon
  align-self: center
  font-size: var(--xl)
  &.active
    color: var(--purple-50)
  &.inactive
    color: var(--gray-50)

.selection-icon
  flex: none
  align-self: center
  cursor: pointer
</style>
