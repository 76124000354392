<template>
  <ChipsGroup v-model="modalityPrivate" :options-data="optionsData" />
</template>

<script>
import { ChipsGroup } from '@seliaco/red-panda'

export default {
  name: 'ModalitySelector',
  components: {
    ChipsGroup
  },
  model: {
    prop: 'modality',
    event: 'changed'
  },
  props: {
    modality: {
      type: String,
      default: 'ONLINE'
    },
    showPresentialOption: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      optionsData: [
        {
          code: 'ONLINE',
          text: this.$translations.option.modality.online,
          icon: 'icon-video-outline-off',
          iconPosition: 'left',
          showIcon: true
        }
      ],
      modalityPrivate: this.modality
    }
  },
  watch: {
    showPresentialOption: {
      handler (value) {
        if (!value) {
          return
        }

        this.optionsData.push({
          code: 'FACE_TO_FACE',
          text: this.$translations.option.modality['face-to-face'],
          icon: 'icon-person-off',
          iconPosition: 'left',
          showIcon: true
        })
      },
      immediate: true
    },
    modalityPrivate: {
      handler (val) {
        this.$emit('changed', val)
      }
    }
  }
}
</script>
